<template>
  <b-modal
    ref="modal"
    v-bind="attrs"
    v-on="$listeners"
  >
    <template #modal-header-close>
      <svg-icon
        size="16"
        name="close"
      />
    </template>

    <template #modal-footer="{ hide, ok }">
      <div class="modal-footer-body">
        <div class="modal-footer-body__content">
          <slot name="footer-content" />
        </div>

        <div class="modal-footer-body__buttons">
          <slot name="modal-cancel">
            <b-button
              :variant="cancelVariant"
              :disabled="cancelDisabled"
              class="btn--md-width"
              :class="cancelClass"
              @click="hide"
            >{{ cancelTitle }}</b-button>
          </slot>
          <slot name="modal-ok">
            <b-button
              :variant="okVariant"
              :disabled="okDisabled || isLoadingOk"
              class="btn--md-width"
              @click="ok"
            >
              <v-spinner
                v-if="isLoadingOk"
                size="20"
              />
              <div>{{ okTitle }}</div>
            </b-button>
          </slot>
        </div>
      </div>
    </template>

    <loading
      v-if="isLoadingContent"
      height="200"
    />

    <template v-for="(_, slot) of isLoadingContent ? [] : $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope"/>
    </template>
  </b-modal>
</template>

<script>
import Vue from 'vue';

export default {
  inheritAttrs: false,
  name: "base-modal",
  props: {
    isLoadingOk: { type: Boolean, default: false },
    isLoadingContent: { type: Boolean, default: false },
    modalClass: { type: [Array, String, Object], default: '' },
    okVariant: { type: String, default: 'primary' },
    okDisabled: { type: Boolean, default: false },
    cancelVariant: { type: String, default: 'ghost-primary' },
    cancelDisabled: { type: Boolean, default: false },
    okTitle: {
      type: String,
      default: () => Vue.prototype.$vDict('global.text_ok.text'),
    },
    cancelTitle: {
      type: String,
      default: () => Vue.prototype.$vDict('global.button_cancel.text'),
    },
  },
  computed: {
    attrs() {
      const modalClasses = [
        'base-modal',
        { 'is-loading': this.isLoadingContent },
      ];

      return {
        'title-tag': 'h1',
        'modal-class': Array.isArray(this.modalClass) ?
          [...modalClasses, ...this.modalClass] :
          [...modalClasses, this.modalClass],
        'hide-footer': this.isLoadingContent || this.$attrs.hideFooter,
        ...this.$attrs,
      };
    },
    cancelClass() {
      return this.cancelVariant === 'link' ?
        'btn-link btn-link--shaded btn-link--uppercase' : '';
    },
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide(true);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
