<template>
  <div class="entity-list-view">
    <div
      v-if="entityList.isLoadingOperation"
      class="entity-list-view__loader"
    >
      <loading height="40" />
    </div>

    <div
      v-if="currentView.key === ENTITY_LIST_VIEWS.LIST"
      ref="viewList"
      key="view-list"
      class="view-list"
    >
      <!-- Table view -->
      <component
        :is="entityList.tableView"
        :sort="sort"
        :fields="fields"
        :items="items"
        :entity-list="entityList"
        :fixed-required-columns="fixedRequiredColumns"
        :tbody-tr-class="rowClass"
        @change-sort="$emit('change-sort', $event)"
        @hook:mounted="onReadyView"
      />
    </div>
    <div
      v-else-if="currentView.key === ENTITY_LIST_VIEWS.CARD"
      key="view-cards"
      ref="viewCard"
      class="view-cards"
    >
      <!-- Cards view -->
      <cards-view
        :fields="fields"
        :items="items"
        :entity-list="entityList"
        :offset-index="entityList.offsetIndex"
        :selectable="entityList.config.hasBulkOperations"
        is-grid-layout
        @ready="onReadyView"
      />
    </div>
    <div
      v-else-if="currentView.key === ENTITY_LIST_VIEWS.MAP"
      key="view-map"
      ref="viewMap"
      class="view-map"
    >
      <!-- Map view -->
      <map-view
        :fields="fields"
        :entity-list="entityList"
        @hook:mounted="onReadyView"
      />
    </div>
    <div
      v-else-if="currentView.isCustomView"
      :key="currentView.key"
      class="custom-view"
      :class="`view-${currentView.key}`"
    >
      <slot
        :name="`view(${currentView.key})`"
        :ready="onReadyView"
      />
    </div>
  </div>
</template>

<script>
import { ENTITY_LIST_VIEWS } from "@/config/enums";

const CardsView = () => import('@/components/entity-list/views/cards/CardsView');
const MapView = () => import('@/components/entity-list/views/map/MapView');

export default {
  name: 'entity-list-view',
  components: {
    CardsView,
    MapView,
  },
  props: {
    entityList: { type: Object, default: Object },
    items: { type: Array, default: Array },
    fields: { type: Array, default: Array },
    sort: { type: Object, default: Object },
    fixedRequiredColumns: { type: Boolean, default: false },
    currentView: { type: Object, default: Object },
  },
  data() {
    return {
      ENTITY_LIST_VIEWS,
    };
  },
  methods: {
    onReadyView() {
      this.$emit('mounted');
    },
    rowClass(entity) {
      if (!this.entityList.config.isMain || !this.entityList.selfClass.isUserSection) {
        return;
      }

      return {
        'entity-row': true,
        'entity-row--unviewed': !entity.isViewed(),
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.entity-list-view {
  position: relative;
  min-height: 100px;

  &__loader {
    position: absolute;
    background-color: rgba(255, 255, 255, .6);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;

    .loading {
      position: sticky;
      top: calc(50% - 20px);
    }
  }
}

::v-deep .entity-row {
  --border-unviewed-color: transparent;

  >td:first-child {
    &::before {
      --height: 87%;
      content: '';
      position: absolute;
      top: calc((100% - var(--height)) / 2);
      left: 0;
      width: 3px;
      height: var(--height);
      background-color: var(--border-unviewed-color);
    }
  }

  &--unviewed {
    --border-unviewed-color: var(--primary);
  }
}
</style>
